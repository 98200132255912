import React from "react";
import playIcon from "../../assets/hearingDetails/play.svg";
import pauseIcon from "../../assets/hearingDetails/pause.svg";
import backIconDisabled from "../../assets/hearingDetails/backIcon_disabled.svg";
import backIcon from "../../assets/hearingDetails/back.svg";
import advanceIcon from "../../assets/hearingDetails/advance.svg";
import fullscreenIcon from "../../assets/hearingDetails/fullscreen.svg";
import volumeIcon from "../../assets/hearingDetails/volume.svg";
import volumeMuteIcon from "../../assets/hearingDetails/volume-mute.svg";
import chevronDown from "../../assets/hearingDetails/chevron-down.svg";
import checkDrop from "../../assets/svg/check_drop.svg";

import {
  Container,
  LeftButton,
  CenterButton,
  RightButton,
  TimeButton,
  PlayButton,
  Time,
  ButtonIcon,
  ButtonIconVolume,
  PlaybackRate,
  PlaybackRateMenu,
  PlaybackRateContainer,
  PlayerContainer,
  ItemRateSelected,
} from "./styles";
import PercentageBar from "../../components/PercentageBar";

type IPlayerControl = {
  playerState: {
    playing: boolean;
    progress: number;
    duration: string | number;
    currentTime: string | number;
    volume: number;
    playbackRate: number;
  };
  disabled: boolean;
  toggleVideoPlay: () => unknown;
  toggleChangeProgress: (progress: number) => unknown;
  toggleFullscreen: () => unknown;
  toggleFiveSeconds: (type: "advance" | "back") => unknown;
  toggleVolume: (volume: number) => unknown;
  toggleRate: (rate: number) => unknown;
};

const PlayerControl: React.FC<IPlayerControl> = ({
  playerState,
  toggleVideoPlay,
  toggleFullscreen,
  toggleChangeProgress,
  toggleFiveSeconds,
  toggleVolume,
  toggleRate,
  disabled,
}) => {
  const disabledBack =
    (typeof playerState.currentTime === "number" &&
      playerState.currentTime <= 0) ||
    playerState.currentTime === "00:00";
  const disabledAdvance = playerState.currentTime >= playerState.duration;

  const playbackRateList = [
    { id: 2, value: 0.5, label: "0.5" },
    { id: 3, value: 0.75, label: "0.75" },
    { id: 4, value: 1, label: "Normal" },
    { id: 5, value: 1.25, label: "1.25" },
    { id: 6, value: 1.5, label: "1.5" },
    { id: 7, value: 1.5, label: "1.75" },
    { id: 8, value: 2, label: "2" },
  ];

  return (
    <PlayerContainer>
      <PercentageBar
        changePercentage={toggleChangeProgress}
        percent={playerState.progress}
      />
      <Container>
        <LeftButton>
          <ButtonIconVolume>
            <div id="volume_container">
              <input
                id="volume"
                min="0"
                max="100"
                value={Number(playerState.volume * 100)}
                onChange={(e) => toggleVolume(+e.target.value / 100)}
                type="range"
              />
            </div>
            <img
              src={playerState.volume ? volumeIcon : volumeMuteIcon}
              alt=""
            />
          </ButtonIconVolume>
        </LeftButton>
        <CenterButton>
          <TimeButton
            onClick={() => toggleFiveSeconds("back")}
            disabled={disabledBack || disabled}
          >
            <img src={disabledBack ? backIconDisabled : backIcon} alt="" />
          </TimeButton>
          <PlayButton
            disabled={disabled}
            playing={playerState.playing}
            onClick={toggleVideoPlay}
          >
            <img src={playerState.playing ? pauseIcon : playIcon} alt="" />
          </PlayButton>
          <TimeButton
            disabled={disabledAdvance || disabled}
            onClick={() => toggleFiveSeconds("advance")}
          >
            <img src={advanceIcon} alt="" />
          </TimeButton>
          <Time>
            {typeof playerState.currentTime === "number" &&
            playerState.currentTime < 10
              ? `0${playerState.currentTime.toString().replace(".", ":")}`
              : playerState.currentTime}{" "}
            /{" "}
            {typeof playerState.duration === "number" &&
            playerState.duration < 10
              ? `0${playerState.duration.toString().replace(".", ":")}`
              : playerState.duration}
          </Time>
        </CenterButton>
        <RightButton>
          <PlaybackRateContainer>
            <PlaybackRate>
              <div>
                {playerState.playbackRate}
                <strong>x</strong>
              </div>
              <img src={chevronDown} alt="" />
            </PlaybackRate>
            <PlaybackRateMenu id="playbackList">
              {playbackRateList.map((item) => (
                <ItemRateSelected
                  selected={item.value === playerState.playbackRate}
                  onClick={() => toggleRate(item.value)}
                  key={item.id}
                >
                  {item.label}
                  {item.value === playerState.playbackRate && (
                    <img src={checkDrop} alt="" />
                  )}
                </ItemRateSelected>
              ))}
            </PlaybackRateMenu>
          </PlaybackRateContainer>
          <ButtonIcon onClick={toggleFullscreen}>
            <img src={fullscreenIcon} alt="" />
          </ButtonIcon>
        </RightButton>
      </Container>
    </PlayerContainer>
  );
};

export default PlayerControl;
